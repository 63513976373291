import React, { useState } from "react"
import ReCAPTCHA from "react-google-recaptcha"

function ContactForm() {
  const recaptchaRef = React.useRef()
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  const requiredFieldText = "Sorry, but this field cannot be left empty."
  const invalidEmailText = "Hmm... that email address doesn't look right."
  const captchaErrorText = "We just need to make sure you aren't a robot."
  const formSubmitErrorText =
    "Shoot! There was an error submitting the form, please try again later."
  const [formSubmitError, setFormSubmitError] = useState()
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [formSubmitting, setFormSubmitting] = useState(false)
  const [formError, setFormError] = useState({
    name: "",
    _replyto: "",
    message: "",
    captchaRes: "",
  })
  const [formData, setFormData] = useState({
    name: "",
    _replyto: "",
    message: "",
    captchaRes: "",
  })
  const isFormValid = () => {
    let valid = true
    let errors = {}
    for (const key in formData) {
      const value = formData[key]
      if (!value) {
        valid = false
        errors[key] = requiredFieldText
      } else if (key === "_replyto") {
        if (!emailRegex.test(formData["_replyto"])) {
          valid = false
          errors[key] = invalidEmailText
        }
      }
    }
    if (!recaptchaRef.current.getValue()) {
      valid = false
      errors["captchaRes"] = captchaErrorText
    }
    if (valid) {
      return true
    } else {
      setFormError(errors)
      return false
    }
  }

  const handleChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    setFormData({ ...formData, [name]: value })
  }
  const handleCaptchaChange = () => {
    const captchaValue = recaptchaRef.current.getValue()
    if (captchaValue) {
      setFormData({ ...formData, captchaRes: captchaValue })
      setFormError({ ...formError, captchaRes: "" })
    }
  }
  const handleBlur = event => {
    let errors = {}
    const target = event.target
    if (!target.value) {
      errors[target.name] = requiredFieldText
      target.classList.remove("success")
      target.classList.add("error")
    } else if (target.name === "_replyto") {
      if (!emailRegex.test(target.value)) {
        errors[target.name] = invalidEmailText
        target.classList.remove("success")
        target.classList.add("error")
      } else {
        errors[target.name] = ""
        target.classList.remove("error")
        target.classList.add("success")
      }
    } else {
      errors[target.name] = ""
      target.classList.remove("error")
      target.classList.add("success")
    }
    setFormError({ ...formError, ...errors })
  }
  const handleSubmit = async () => {
    setFormSubmitting(true)
    if (isFormValid()) {
      fetch("https://formspree.io/f/xknybvye", {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: JSON.stringify({
          name: formData.name,
          email: formData._replyto,
          message: formData.message,
          "g-recaptcha-response": formData.captchaRes,
        }),
      })
        .then(response => response.json())
        .then(data => {
          if (data.ok === true) {
            setFormSubmitted(true)
          } else if (data.error) {
            setFormSubmitError(formSubmitErrorText)
            console.log("Server error", data)
          }
        })
        .catch(error => {
          console.error("Error", error)
          setFormSubmitError(formSubmitErrorText)
        })
    }
    setFormSubmitting(false)
  }
  if (formSubmitted) {
    return <h4>Thanks for getting in touch!</h4>
  }

  return (
    <form
      onSubmit={event => {
        event.preventDefault()
        handleSubmit()
      }}
    >
      {formSubmitError && <p className="error form-error">{formSubmitError}</p>}
      <label htmlFor="name">Name</label>
      <input
        type="text"
        name="name"
        value={formData.name}
        placeholder="Jessica Smith"
        // required={true}
        onChange={event => {
          handleChange(event)
        }}
        onBlur={event => {
          handleBlur(event)
        }}
      />
      {formError.name && <p className="error">{formError.name}</p>}
      <label htmlFor="email">Email Address</label>
      <input
        type="text"
        name="_replyto"
        value={formData._replyto}
        placeholder="yourname@email.com"
        // required={true}
        onChange={event => {
          handleChange(event)
        }}
        onBlur={event => {
          handleBlur(event)
        }}
      />
      {formError._replyto && <p className="error">{formError._replyto}</p>}
      <label htmlFor="message">Message</label>
      <textarea
        name="message"
        // required={true}
        value={formData.message}
        placeholder="Hey! I would be interested in working with you."
        onChange={event => {
          handleChange(event)
        }}
        onBlur={event => {
          handleBlur(event)
        }}
      />
      {formError.message && <p className="error">{formError.message}</p>}
      <ReCAPTCHA
        className="recaptcha"
        ref={recaptchaRef}
        sitekey="6LdSk7UeAAAAAMTcBLxSc4hrJu3Aa7yRNckGGcRf"
        theme="dark"
        onChange={event => {
          handleCaptchaChange(event)
        }}
      />
      {formError.captchaRes && <p className="error">{formError.captchaRes}</p>}
      <button type="submit" disabled={formSubmitting}>
        {formSubmitting ? "Submitting" : "Submit"}
      </button>
    </form>
  )
}

export default ContactForm
