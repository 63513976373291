import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Seo from "../components/seo"
import { Icon } from "@iconify/react"
import ReactMarkdown from "react-markdown"
import remarkGfm from "remark-gfm"
import ContactForm from "../components/ContactForm"

const About = () => {
  const data = useStaticQuery(graphql`
    query ContactQuery {
      strapiContact {
        body
        socials {
          title
          url
          iconify_icon
        }
        seo {
          metaTitle
          metaDescription
          preventIndexing
          sharedImage {
            alt
            media {
              url
            }
          }
        }
      }
    }
  `)

  const strapiSeo = data.strapiContact.seo
  return (
    <>
      <Seo
        title={strapiSeo.metaTitle}
        description={strapiSeo.metaDescription}
        image={strapiSeo.sharedImage.media.url}
        imageAlt={strapiSeo.sharedImage.alt}
      />
      <div className="page-content">
        <header className="upper-content">
          <div className="header-content">
            <h1>About me</h1>
            <ReactMarkdown
              remarkPlugins={[[remarkGfm, { singleTilde: false }]]}
            >
              {data.strapiContact.body}
            </ReactMarkdown>
          </div>
        </header>
        <main className="lower-content">
          <div className="contact-container">
            <div className="connect-container">
              <h3 className="socials">Connect:</h3>
              <ul className="social-links">
                {data.strapiContact.socials.map((item, index) => {
                  return (
                    <a href={item.url} key={index}>
                      <li>
                        <Icon
                          className="icon"
                          icon={item.iconify_icon}
                          width="35"
                        />
                        <h4>{item.title}</h4>
                      </li>
                    </a>
                  )
                })}
              </ul>
            </div>

            <div className="contact-form">
              <h3>Contact Me:</h3>
              <ContactForm />
            </div>
          </div>
        </main>
      </div>
    </>
  )
}

export default About
